import React from 'react';
import { Col } from "../layot/Flex";
import GeneralBlock from './GeneralBlock/GeneralBlock';
import Header from '../layot/Header'
import MainImage from './MainImage';
import Stepper from './Stepper/Stepper';
import { Typography } from "@mui/material";

const LendingPage = () => {
  return (
    <Col style={{position: "relative"}}>
      <Header/>
      <MainImage/>
      <Col style={{justifySelf: "center", alignSelf: "center"}} m="-300px 0 0 0">
        <Typography variant="h3" component="h3" style={{marginBottom: '56px', color: 'white'}}>
          Welcome!
        </Typography>
        <Stepper />
        <GeneralBlock/>
      </Col>
    </Col>
  );
};

export default LendingPage;
