import { Alert, Button, MenuItem, Select, SelectChangeEvent, Snackbar, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { creditProductApi } from '../../api/apiCreditProduct'
import { Col, Row } from '../../layot/Flex'
import LoanAmount from '../../layot/LoanAmount'
import Term from '../../layot/Term'
import { ICalculate } from '../../types/ICalculate'
import Calculate from '../Calculate'
import { useDebouncedCallback } from 'use-debounce'
import { Context } from '../../../App'
import { getSliderDays } from '../../../helpers/dateHelpers'
import { ILoanTerm, IPostShopRequestPayload } from '../../../interfaces'
import daysInYear from '../../../helpers/daysInYear'
import moment from 'moment'
import Loader from '../../layot/Loader'
import warningIcon from '../../assets/images/icons/warning.png'
import styles from './GeneralBlock.module.scss'
import {mobileMaxWidth, getWindowDimensions} from "../../../helpers/resize";

interface IProduct {
  id: string
  name: string
  is_prepayment: boolean
  loan_amount_limit_from: number
  loan_amount_limit_to: number
  loan_term_limit_from: number
  loan_term_limit_to: number
  year_dpr: number
  percent_accrual_date_n: number
  repayment_frequency_n: number
  overdue_dpr: number
  credit_type: string
  created_at: string
  updated_at: string
  client_type: number
  percent_accrual_date_type: number
  percent_accrual_date_n_period: number
  percent_accrual_schema: number
  percent_accrual_type: number
  regions: null | string[]
  repayment_frequency: number
  repayment_frequency_n_period: number
  scoring_model: number
}

export interface IGenerate {
  products: IProduct[]
  creditProduct: IProduct | null
  loanAmount: string
  term: string
  period: string
  payment: string
}

export interface ITermData {
  inMonths: boolean
  loan_date_from: number
  loan_date_to: number
}

interface IPromoCode {
  promoCode: string | null
  status?: 'init' | 'success'
  message?: string | null
  errors?: string
  isLoading?: boolean
}

const GeneralBlock = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const { sideBlock, setSideBlock } = useContext(Context)
  const [generalState, setGeneralState] = useState<IGenerate>({
    products: [],
    creditProduct: null,
    loanAmount: '',
    term: '',
    period: '',
    payment: ''
  })

  const [currentContainerHeight, setCurrentContainerHeight] = useState<number>(0)
  const settingsBlockRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const [chosenRegion, setChosenRegion] = useState('')
  const [selectedTerm, setSelectedTerm] = useState('')

  const [promoCode, setPromoCode] = useState<IPromoCode>({
    promoCode: '',
    status: 'init',
    message: null,
    isLoading: false
  })

  const currentYear = new Date().getFullYear()
  const daysInCurrentYear = daysInYear(currentYear)
  const daysInCurrentMonth = moment().daysInMonth()

  const handleChangeRegion = (event: SelectChangeEvent) => {
    setChosenRegion(event.target.value as string)
  }

  const { creditProduct, loanAmount, term } = generalState

  const finalTerm = () => {
    const daysRemainingInCurrentMonth = moment().endOf('month').diff(moment(), 'days')
    const daysPassedInMonth = daysInCurrentMonth - daysRemainingInCurrentMonth
    const finalDate = moment(new Date())
      .add(+term - 1, 'month')
      .endOf('month')
      .toDate()
    const daysAmount = moment(finalDate).diff(new Date(), 'days') + daysPassedInMonth + 1

    if (selectedTerm === 'years') {
      return +term * daysInCurrentYear
    }
    if (selectedTerm === 'months') {
      return daysAmount
    }
    if (selectedTerm === 'days') {
      return +term
    }
  }

  const regionsList = generalState.products.find(
    product => product.id === generalState.creditProduct?.id
  )?.regions

  useEffect(() => {
    const fetchData = async () => {
      const data = await creditProductApi.creditProduct.getCreditProduct()

      setGeneralState(prevState => ({
        ...prevState,
        products: data.data.filter(
          (val: any) => val.credit_type === 'Money on the card' && val.visible_on_calculator
        )
      }))
    }

    fetchData().catch(err => console.error(err))
  }, [])

  const handleConfirmPromoCode = () => {
    setPromoCode({ ...promoCode, isLoading: true })
    getLoanAmountWithDelay()
  }

  const getLoanAmountWithDelay = useDebouncedCallback(() => {
    if (creditProduct && loanAmount && term) {
      creditProductApi.creditProduct
        .getCalculateLoanAmount({
          credit_policy_id: +creditProduct.id,
          amount: +loanAmount,
          loan_term: finalTerm(),
          category_id: 1,
          promo_code: promoCode.promoCode
        } as any)
        .then(({ data }) => {
          setSideBlock({ ...sideBlock, isLoading: false })
          !!promoCode.promoCode && !promoCode.errors && setPromoCode(prev => ({ ...prev, status: 'success', message: 'Promo code successfully applied', isLoading: false }))
          let apr = 0
          let commission = 0
          let frequency = 0
          let repayment_amount = 0
          let interest = 0
          let principal = 0
          let loanTerm = finalTerm()
          let total = 0

          data.forEach((calculateLoanAmount: any) => {
            principal += +calculateLoanAmount.principal
            interest += +calculateLoanAmount.interest
            commission += +calculateLoanAmount.commission
          })

          total = +principal + +interest + +commission

          const schemaFormula = generalState.creditProduct && generalState.creditProduct.percent_accrual_schema === 1 ? 365 : 360

          if(loanTerm) apr = (((interest + commission) / principal) / loanTerm * schemaFormula) * 100

          setSideBlock({
            apr,
            commission,
            frequency,
            repayment_amount,
            interest,
            principal,
            total
          } as unknown as ICalculate)
        })
        .catch((err: any) => {
          setSideBlock({
            ...sideBlock,
            isLoading: false
          })
          setPromoCode(prev => ({
            ...prev,
            promoCode: '',
            errors: err.response.data.non_field_errors[0],
            isLoading: false
          }))
        })
    }
  }, 2000)

  const [termData, setTermData] = useState<ITermData | undefined>()

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (settingsBlockRef.current) {
      const resizeHandler = () => {
        setCurrentContainerHeight(windowDimensions.height)
        setTimeout(() => setCurrentContainerHeight(settingsBlockRef?.current?.clientHeight), 10)
      }

      window.addEventListener('resize', resizeHandler)
      setCurrentContainerHeight(settingsBlockRef?.current?.clientHeight)

      return () => window.removeEventListener('resize', resizeHandler)
    }
  }, [settingsBlockRef])

  useEffect(() => {
    creditProduct &&
      loanAmount &&
      term &&
      setSideBlock({
        ...sideBlock,
        isLoading: true
      })
    getLoanAmountWithDelay()
  }, [creditProduct, getLoanAmountWithDelay, loanAmount, setSideBlock, term])

  return (
    <Col className={styles.generalBlock}>
      <Row ref={settingsBlockRef} className={styles.settingsBlock}>
        <Col p="25px" style={{ fontSize: '22px' }}>
          <Row className={styles.creditSelections}>
            <Col>
              <p>Credit product</p>
              <Select
                style={{
                  width: '100%',
                  borderRadius: '30px',
                  border: '1px solid #B5B5B5',
                  maxWidth: (windowDimensions.width <= mobileMaxWidth && '200px') || undefined
                }}
                value={generalState.creditProduct ? generalState.creditProduct.id : 0}
                defaultValue={0}
                onChange={e => {
                  const product = generalState?.products?.find(
                    (product: any) => product.id === e.target.value
                  )
                  if (product) {
                    const { loan_amount_limit_from, loan_amount_limit_to } = product

                    const sliderDateFormat = getSliderDays({
                      loan_date_from: loan_amount_limit_from,
                      loan_date_to: loan_amount_limit_to
                    } as ILoanTerm)

                    setTermData(sliderDateFormat)

                    setGeneralState({
                      ...generalState,
                      creditProduct: generalState?.products?.find(
                        (product: any) => product.id === e.target.value
                      ) as any
                    })
                    setChosenRegion('')
                  }
                }}
              >
                <MenuItem disabled value={0}>
                  Select product
                </MenuItem>
                {generalState?.products?.map((prod: any) => (
                  <MenuItem key={prod.id} value={prod.id}>
                    <span>{prod.name}</span>
                  </MenuItem>
                ))}
              </Select>
            </Col>
            { generalState.creditProduct && regionsList && (
                <Col>
                  <p className={styles.regionTitle}>Region
                    { !chosenRegion &&
                        <img src={warningIcon} alt="Need choose icon" className={styles.warningIcon}/>
                    }
                  </p>
                  <Select
                      style={{
                        width: '100%',
                        borderRadius: '30px',
                        border: '1px solid #B5B5B5',
                        maxWidth: (windowDimensions.width <= mobileMaxWidth && '200px') || undefined
                  }}
                      value={chosenRegion}
                      onChange={handleChangeRegion}
                      disabled={!generalState.creditProduct?.regions}
                  >
                    {regionsList &&
                        regionsList.map((region: any) => (
                            <MenuItem key={region} value={region}>
                              {region}
                            </MenuItem>
                        ))}
                  </Select>
                </Col>
            ) }
          </Row>

          <LoanAmount
            setGeneralState={setGeneralState}
            generalState={generalState}
            product={generalState.creditProduct}
            region={chosenRegion}
            windowWidth={windowDimensions.width}
          />

          <Term
            setGeneralState={setGeneralState}
            setSelectedTerm={setSelectedTerm}
            generalState={generalState}
            product={generalState.creditProduct}
            region={chosenRegion}
            daysInCurrentYear={daysInCurrentYear}
            daysInCurrentMonth={daysInCurrentMonth}
            windowWidth={windowDimensions.width}
          />

          <div
            style={{
              marginRight: 'auto',
              marginBottom: '36px',
              display: 'flex',
              flexDirection: 'column',
              gap: '15px'
            }}
          >
            <Typography className={styles.additionalInfo}>
              {`Repayment period: ${sideBlock.frequency}`}
            </Typography>
            <Typography className={styles.additionalInfo}>
              {`Approximate payment: $${Math.ceil(sideBlock.repayment_amount)}`}
            </Typography>
          </div>

          <Row style={{ borderTop: '1px solid #E2E0E0' }} />

          <Row className={styles.promoCode} alignItems="flex-start">
            <TextField
              className="inputRounded"
              style={{ width: '100%' }}
              id="outlined-basic"
              label="Enter promo code"
              variant="outlined"
              error={!!promoCode.errors}
              helperText={promoCode.errors}
              value={promoCode.promoCode}
              onChange={e => setPromoCode({ promoCode: e.target.value })}
            />
            <Row m="0" p="0" alignItems="flex-start" justifyContent="flex-start">
              <Button
                className={styles.promoCodeBtn}
                variant="outlined"
                disabled={!loanAmount || !term || !promoCode.promoCode}
                onClick={handleConfirmPromoCode}
              >
                {promoCode.isLoading ? <Loader className={styles.loader} /> : 'Confirm Promo Code'}
              </Button>
            </Row>
          </Row>
        </Col>

        <Calculate parentHeight={currentContainerHeight} />
      </Row>
      <Row>
        <Button
          style={{ marginTop: '30px', borderRadius: '30px' }}
          variant="contained"
          disabled={!loanAmount || !term || !creditProduct || !chosenRegion}
          onClick={() => {
            creditProductApi.creditProduct
              .postShopRequest({
                product_name: 'Money on card',
                credit_policy_id: generalState.creditProduct?.id,
                product_price: +loanAmount,
                loan_term: finalTerm(),
                product_category_id: 1,
                shop_id: 1,
                client_name: 'Empty',
                region: chosenRegion,
                promo_code: promoCode.status === 'success' ? promoCode.promoCode : ''
              } as IPostShopRequestPayload)
              .then(res => {
                window.location.href = res.data.url
              })
          }}
        >
          Apply
        </Button>
      </Row>
      { promoCode.message &&
          <Snackbar
              open={!!promoCode.message}
              autoHideDuration={4000}
              onClose={() => setPromoCode({...promoCode, message: null})}
          >
            <Alert
                variant="filled"
                severity="info"
            >
              {promoCode.message}
            </Alert>
          </Snackbar>
      }
    </Col>
  )
}

export default GeneralBlock
