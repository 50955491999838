import { CommercePage } from 'components/pages/CommercePage'
import React, { createContext, useState } from 'react'
import { Route, Routes } from 'react-router'

import LendingPage from './components/pages/LendingPage'
import { ICalculate } from './components/types/ICalculate'

export const Context = createContext({} as IContext)

interface IContext {
  sideBlock: ICalculate
  setSideBlock: React.Dispatch<ICalculate>
}

const App = () => {
  const [sideBlock, setSideBlock] = useState({
    principal: 0,
    interest: 0,
    commission: 0,
    total: 0,
    apr: 0,
    approximate_payment: 0,
    frequency: '',
    repayment_amount: 0,
    isLoading: false
  })

  return (
    <Context.Provider value={{ sideBlock, setSideBlock }}>
      <Routes>
        <Route element={<LendingPage />} path="/home" />
        <Route element={<CommercePage />} path="/commerce" />
      </Routes>
    </Context.Provider>
  )
}
export default App
