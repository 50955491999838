import styled from "styled-components";
import { Element } from "./Elements";

const Flex = styled(Element)<{ alignItems?: string; justifyContent?: string }>`
  display: flex;
  align-items: ${(props) => props.alignItems || "center"};
  justify-content: ${(props) => props.justifyContent || "center"};
`;

export const Row = styled(Flex)<{ flexWrap?: string }>`
  flex-wrap: ${(props) => props.flexWrap || "none"};
`;
export const Col = styled(Flex)`
  flex-direction: column;
`;
