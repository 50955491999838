import styled from "styled-components";

export interface defaultElementsProps {
    w?: string,
    h?:string,
    m?: string,
    bg?: string,
    p?: string,
    b?: string,
    br?: string,
    fw?: string,
    fs?: string,
    bb?: string,
    bbc?: string,
    tt?: string
    
}

export const Element = styled.div<defaultElementsProps>`
    width: ${(props) => props.w || "100%"};
    height: ${(props) => props.h || "100%"};
    margin: ${(props) => props.m || "none"};
    padding: ${(props) => props.p || "none"};
    background: ${(props) => props.bg || "none"};
    font-weight: ${(props) => props.fw || "none"};
    font-size: ${(props) => props.fs || "none"}
`;
export const Button = styled.button<defaultElementsProps>`
    width: ${(props) => props.w || "auto"};
    height: ${(props) => props.h || "auto"};
    margin: ${(props) => props.m || "10px"};
    padding: ${(props) => props.p || "12px"};
    border: ${(props) => props.b || "none"};
    border-radius: ${(props) => props.br || "20px"};
    background: ${(props) => props.bg || "#FFF86B"};
    border-bottom: ${(props) => props.bb || "0px"};
    border-bottom-color: ${(props) => props.bbc || "none"};
    font-size: 18px;
    text-transform: ${(props) => props.tt || "uppercase"};
    cursor: pointer;
    transition: 0.3s all;
    &:hover{background: #f4f7d7; color: black!important};

    & a {
        text-decoration: none !important;
        color: black;
    }
`
export const Image = styled.img<defaultElementsProps & {circle?: boolean}>`
border-radius: ${(props) => props.circle ? "50%" : "none"};
width: ${(props) => props.w || "auto"};
height: ${(props) => props.h || "auto"};
`
export const Form = styled.form<defaultElementsProps>`
    width: ${(props) => props.w || "auto"};
    height: ${(props) => props.h || "auto"};
    margin: ${(props) => props.m || "10px"};
    padding: ${(props) => props.p || "12px"};
    border: ${(props) => props.b || "none"};
    border-radius: ${(props) => props.br || "20px"};
    background: ${(props) => props.bg || "#FFF86B"};
    border-bottom: ${(props) => props.bb || "0px"};
    border-bottom-color: ${(props) => props.bbc || "none"};
    font-size: 18px;
    text-transform: ${(props) => props.tt || "uppercase"};
    cursor: pointer;
    transition: 0.3s all;
    &:hover{background: #f4f7d7; color: black!important};

    & a {
        text-decoration: none !important;
        color: black;
    }
`
