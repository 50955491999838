import React, { useEffect, useState } from 'react'
import { TextField, Slider, Select, MenuItem, SelectChangeEvent } from '@mui/material'
import { Col, Row } from './Flex'
import { IGenerate } from '../pages/GeneralBlock/GeneralBlock'
import { mobileMaxWidth } from '../../helpers/resize'

interface IProps {
  product: any
  setGeneralState: any
  setSelectedTerm: (selectedTerm: string) => void
  generalState: any
  region: string
  daysInCurrentYear: number
  daysInCurrentMonth: number
  windowWidth: number
}

const Term: React.FC<IProps> = ({
  product,
  setGeneralState,
  setSelectedTerm,
  generalState,
  region,
  daysInCurrentYear,
  daysInCurrentMonth,
  windowWidth
}) => {
  const { term } = generalState

  const [chosenTerm, setChosenTerm] = useState('')

  const minTerm = () => {
    if (chosenTerm === 'years') {
      return Math.ceil(product?.loan_term_limit_from / daysInCurrentYear)
    }
    if (chosenTerm === 'months') {
      return Math.ceil(product?.loan_term_limit_from / daysInCurrentMonth)
    }
    if (chosenTerm === 'days') {
      return product?.loan_term_limit_from
    }
  }

  const maxTerm = () => {
    if (chosenTerm === 'years') {
      return Math.floor(product?.loan_term_limit_to / daysInCurrentYear)
    }
    if (chosenTerm === 'months') {
      return Math.floor(product?.loan_term_limit_to / daysInCurrentMonth)
    }
    if (chosenTerm === 'days') {
      return product?.loan_term_limit_to
    }
  }

  useEffect(() => {
    if (product) {
      if (product?.loan_term_limit_to < daysInCurrentMonth) {
        setChosenTerm('days')
      }
      if (product?.loan_term_limit_to > daysInCurrentMonth) {
        setChosenTerm('months')
      }
      if (product?.loan_term_limit_to > daysInCurrentYear) {
        setChosenTerm('years')
      }
    }
  }, [product?.loan_term_limit_to])

  useEffect(() => {
    setSelectedTerm(chosenTerm)
    setGeneralState({ ...generalState, term: minTerm() })
  }, [chosenTerm, generalState.creditProduct])

  const handleChangeTerm = (event: SelectChangeEvent) => {
    setChosenTerm(event.target.value as string)
  }

  return (
    <Col alignItems="flex-start" style={{ margin: '0px 0 30px 0' }}>
      <Row
        justifyContent="space-between"
        style={{
          width: windowWidth <= mobileMaxWidth ? '100%' : undefined,
          flexDirection: windowWidth <= mobileMaxWidth ? 'column' : 'row'
        }}
      >
        <p style={{ width: '20%' }}>Term</p>
        <Row className="termSelect">
          <Row
            style={{
              border: '1px solid grey',
              width: windowWidth <= mobileMaxWidth ? '30px' : '15%',
              padding: '5px 30px',
              borderRadius: '30px'
            }}
          >
            <Select
              disableUnderline
              variant="standard"
              style={{ borderRadius: '30px' }}
              value={chosenTerm}
              onChange={handleChangeTerm}
              disabled={!!product?.regions ? !region.length : false}
            >
              <MenuItem value="days">Days</MenuItem>
              <MenuItem value="months" disabled={product?.loan_term_limit_to < daysInCurrentMonth}>
                Months
              </MenuItem>
              <MenuItem
                value="years"
                disabled={
                  product?.loan_term_limit_to < daysInCurrentMonth ||
                  product?.loan_term_limit_to < daysInCurrentYear
                }
              >
                Years
              </MenuItem>
            </Select>
          </Row>
          <TextField
            className="termInputValue"
            variant="standard"
            id="outlined-number-term-value"
            type="number"
            size="medium"
            value={term}
            style={{ textAlign: 'center', minWidth: '50px' }}
            onChange={e => {
              if (`${e.target.value}`.includes('e')) return

              setGeneralState((prev: IGenerate) => ({
                ...prev,
                term:
                  (e.target.value > maxTerm() ? maxTerm() : e.target.value) ||
                  (e.target.value < minTerm() ? minTerm() : e.target.value)
              }))
            }}
            InputProps={{
              disabled: !product,
              disableUnderline: true,
              inputProps: {
                min: minTerm(),
                max: maxTerm()
              }
            }}
          />
        </Row>
      </Row>
      {maxTerm() === 1 ? null : (
        <Slider
          style={{ width: '100%' }}
          min={minTerm()}
          max={maxTerm()}
          value={term}
          onChange={(e, term) => {
            setGeneralState((prev: any) => ({ ...prev, term: term as number }))
          }}
          disabled={!product}
        />
      )}
      <Row justifyContent="space-between">
        <span>
          {minTerm()}
          &nbsp;{minTerm() === 1 ? chosenTerm.replace('s', '') : chosenTerm}
        </span>
        {maxTerm() > 1 ? (
          <span>
            {maxTerm()}
            &nbsp;{maxTerm() === 1 ? chosenTerm.replace('s', '') : chosenTerm}
          </span>
        ) : null}
      </Row>
    </Col>
  )
}

export default Term
