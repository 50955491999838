import { CircularProgress } from "@mui/material";
import React from "react";
import { Row } from "./Flex";

interface ILoader {
    className?: string
}

const Loader: React.FC<ILoader> = ({ className }) => {
  return (
    <Row>
      <CircularProgress className={className} />
    </Row>
  );
};

export default Loader;