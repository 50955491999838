import React from "react";
import { Row } from "./Flex";
import { Button } from "@mui/material";
import LanguageMenu from './LanguageMenu'
import { LOGIN_URL } from "config";

const Header = () => {
  return (
    <Row bg="#1B263B" className="header" w="100%">
      <Row justifyContent="space-between">
        <img className="headerLogo" src="/img/icons/logo.svg" alt=""/>
        <Row w="40%" justifyContent="flex-end">
          <LanguageMenu />
          <Button
            style={{
              width: "20%",
              border: "2px solid white",
              borderRadius: "20px",
              marginLeft: "20px",
            }}
            onClick={() => window.location.href = `${LOGIN_URL}`}
            variant="outlined"
          >
            Log In
          </Button>
        </Row>
      </Row>
    </Row>
  );
};

export default Header;
