import { Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import { Row } from '../../layot/Flex';
import styles from './Stepper.module.scss'

const Steper = () => {
  const steps = ["Select loan terms", "Create an account", "Fill application form"]

  return (
    <Row w="100%" m="0 0 30px 0">
      <Row className={styles.stepperContainer}>
        <Stepper style={{width: "100%"}}>
          {steps.map((step) => (
            <Step key={step}>
              <StepLabel>
                {step}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Row>
    </Row>
  );
};

export default Steper;
