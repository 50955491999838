import { ILoanTerm } from "../interfaces";

export const getSliderDays = (loanTerm: ILoanTerm) => {
  if (
    loanTerm.loan_date_to - loanTerm.loan_date_from <= 31 &&
    loanTerm.loan_date_to <= 31
  ) {
    return { ...loanTerm, inMonths: false };
  } else {
    const loan_date_from =
      loanTerm.loan_date_from / 30 < 1 ? 1 : loanTerm.loan_date_from / 30;
    return {
      inMonths: true,
      loan_date_from,
      loan_date_to: loanTerm.loan_date_to / 30,
    };
  }
};