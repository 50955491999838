import React, { useEffect } from 'react'
import { Slider, TextField } from '@mui/material'
import { Col, Row } from './Flex'
import { IGenerate } from "../pages/GeneralBlock/GeneralBlock";
import { mobileMaxWidth } from "../../helpers/resize";

interface IProps {
  product: any
  setGeneralState: any
  generalState: any
  region: string
  windowWidth: number
}

const LoanAmount: React.FC<IProps> = ({ product, setGeneralState, generalState, region, windowWidth }) => {
  const { loanAmount } = generalState

  useEffect(() => {
    if (loanAmount > product?.loan_amount_limit_to) {
        setTimeout(() => setGeneralState((prev: IGenerate) => (
            {...prev, loanAmount: product?.loan_amount_limit_to})
        ), 500)
    }

    if (loanAmount < product?.loan_amount_limit_from) {
        setTimeout(() => setGeneralState((prev: IGenerate) => (
            {...prev, loanAmount: product?.loan_amount_limit_from})
        ), 500)
    }
  }, [loanAmount])

  return (
    <Col alignItems="flex-start" style={{ margin: '20px 0 20px 0' }}>
      <Row justifyContent="space-between" style={{
          flexDirection: windowWidth <= mobileMaxWidth ? 'column' : 'row'
      }}>
        <p
            style={{
                width: windowWidth <= mobileMaxWidth ? '200px' : '20%',
                textAlign: windowWidth <= mobileMaxWidth ? 'center': 'left'
        }}>
            Loan amount
        </p>
        <Row
          w="48%"
          h="40px"
          justifyContent="flex-start"
          style={{
              width: windowWidth <= mobileMaxWidth ? '200px' : undefined,
              border: '1px solid #c6c0c0',
              paddingRight: '10px',
              borderRadius: '30px'
        }}
        >
          <Row style={{ border: '1px solid grey', width: '33%', borderRadius: '30px' }}>$</Row>
          <TextField
            variant="standard"
            style={{ width: '30%', margin: '4px auto 0' }}
            id="outlined-number-loan-amount"
            type="number"
            size="small"
            value={loanAmount}
            onChange={(e: any) => {
              if (`${e.target.value}`.includes('e')) return

              setGeneralState((prev: IGenerate) => ({ ...prev, loanAmount: e.target.value }))
            }}
            InputProps={{
              disabled: !product,
              disableUnderline: true,
              inputProps: {
                max: product && product?.loan_amount_limit_to,
                min: product && product?.loan_amount_limit_from
              }
            }}
          />
        </Row>
      </Row>
      <Slider
        style={{ width: '100%' }}
        min={product && product?.loan_amount_limit_from}
        max={product && product?.loan_amount_limit_to}
        step={100}
        aria-label="Default"
        valueLabelDisplay="auto"
        value={loanAmount}
        onChange={(e, loanAmount) => {
          setGeneralState((prev: any) => ({ ...prev, loanAmount: loanAmount as number }))
        }}
        disabled={!product}
      />
      <Row h="50px" justifyContent="space-between">
        <span>$ {product?.loan_amount_limit_from}</span>
        <span>$ {product?.loan_amount_limit_to}</span>
      </Row>
    </Col>
  )
}

export default LoanAmount
