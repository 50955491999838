import React, { useContext } from 'react'
import { Col, Row } from '../layot/Flex'
import { Context } from '../../App'
import Loader from '../layot/Loader'

export const hugeNumberPipe = (amount: number) => amount.toFixed(2)

interface ICalculateProps {
  parentHeight: number
}

const Calculate: React.FC<ICalculateProps> = ({ parentHeight }) => {
  const { sideBlock, setSideBlock } = useContext(Context)
  const { isLoading } = sideBlock

  return (
      <Col
          bg="#1B263B"
          className="calculate"
          style={{ height: `${parentHeight}px` }}
      >
        { isLoading
            ? <Loader />
            : <>
              <Col>
                <span style={{ color: '#D7E2FF' }}>Principal</span>
                <span>$ {hugeNumberPipe(sideBlock.principal)}</span>
              </Col>
              <div><img src="../img/plus.png" alt="" /></div>
              <Col>
                <span style={{ color: '#D7E2FF' }}>Interest</span>
                <span>$ {hugeNumberPipe(sideBlock.interest)}</span>
              </Col>
              <div><img src="../img/plus.png" alt="" /></div>
              <Col style={{ marginBottom: '50px' }}>
                <span style={{ color: '#D7E2FF' }}>Commission</span>
                <span>$ {hugeNumberPipe(sideBlock.commission)}</span>
              </Col>

              <Row style={{borderTop: '1px dashed #E2E0E0', marginBottom: '50px'}} h="10px"/>

              <Col style={{ color: 'white' }}>
                <span style={{ color: '#D7E2FF' }}>Total</span>
                <span>$ {hugeNumberPipe(sideBlock.total)}</span>
              </Col>

              <Col>
                <span style={{ color: '#D7E2FF' }}>APR</span>
                <span>{Number(hugeNumberPipe(sideBlock.apr))} %</span>
              </Col>
            </>
        }
      </Col>
  )
}

export default Calculate
