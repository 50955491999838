import { IPostShopRequestPayload } from '../../interfaces'
import { IGetCalculateLoanAmountParam } from '../types/ICalculate'
import { axiosInstance } from './api'

export const creditProductApi = {
  creditProduct: {
    getCreditProduct: () => {
      return axiosInstance.get('lender/credit_policies/').catch(err => err.response)
    },
    getCalculateLoanAmount: ({
      credit_policy_id,
      amount,
      loan_term,
      category_id,
      promo_code
    }: IGetCalculateLoanAmountParam) => {
      const data = {
        credit_policy_id,
        amount,
        loan_term,
        category_id,
        promo_code: !!promo_code?.length ? promo_code : null
      }

      return axiosInstance.post('payment-schedule/calculate/', data)
    },
    postShopRequest: (body: IPostShopRequestPayload) => {
      return axiosInstance.post('api_integration/shop_request/', body).catch(err => err.response)
    }
  }
}
